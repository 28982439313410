#printableWoCustDetails {
  display: grid;
  row-gap: 5px;
  grid-template-columns: 0.75fr 0.75fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
    "cust-label cust-name address-label address sales-rep-label sales-rep"
    "phone-label phone-num email-label email project-mgr-label project-mgr"
    ". . . . cod-label cod";
}

#woCustLabel {
  grid-area: cust-label;
  font-weight: bold;
}
  
#woCustName {
  grid-area: cust-name;
}
  
#woAddressLabel {
  grid-area: address-label;
  font-weight: bold;
}

#woAddress {
  grid-area: address;
}

#woSalesRepLabel {
  grid-area: sales-rep-label;
  font-weight: bold;
}

#woSalesRep {
  grid-area: sales-rep;
}

#woPhoneLabel {
  grid-area: phone-label;
  font-weight: bold;
}

#woPhoneNum {
  grid-area: phone-num;
}

#woEmailLabel {
  grid-area: email-label;
  font-weight: bold;
}

#woEmail {
  grid-area: email;
}

#woProjectMgrLabel {
  grid-area: project-mgr-label;
  font-weight: bold;
}

#woProjectMgr {
  grid-area: project-mgr;
}

#woCodLabel {
  grid-area: cod-label;
  font-weight: bold;
}

#woCod {
  grid-area: cod;
}

.printableWoTable {
  width: 100%;
  border-bottom: 3px solid var(--flat-gray-5);
}

.printableWoTableTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--flat-gray-5);
  font-weight: 500;
  color: white !important;
  padding: 5px 0 5px 0;
  text-transform: uppercase;
}

.printableWoTableHeader {
  border-bottom: 1px solid black;
}

@media print {
  #woHeaderContent {
    font-size: 12pt;
    align-items: center !important;
    margin-top: 3px;
  }
}