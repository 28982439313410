#pdf-viewer-dialog {
    position: absolute;
    left: 15%;
    top: 10%;
    height: 80%;
    width: 70%;
    z-index: 9999;
}

#close-pdf-button {
    position: absolute;
    left: 81.5%;
    top: 18%;
    z-index: 9999;
    color: white;
    background-color: var(--flat-gray-6);
}

#delete-pdf-button {
    position: absolute;
    left: 79.5%;
    top: 18%;
    z-index: 9999;
    color: white;
    background-color: var(--flat-gray-6);
}