/* @media print {

    .pagebreak {
        break-after: always;
    }
    size: A4 portrait;
    margin: 1rem;
    size: .7; 
} */


@media print {
    .page-break-after {
        page-break-after: always !important;
    }

    .page-break-before {
        page-break-before: always !important;
    }

    .avoid-page-break-inside {
        page-break-inside: avoid;
    }
}


.total-grid {
    display: grid;
    grid-template-columns: 3fr 5fr 3fr 1fr 2fr 1fr 4fr;
    grid-template-rows: 2fr repeat(4, 1fr);
    height: 12rem;
    min-width: 30rem;
    text-align: center;
    border: 1px solid black;
    --gray-bkg: #ddddddff;
}

.total-grid>div {
    border: 1px solid black;
}

.invoice-total-label {
    grid-row: 1 / 2;
    background-color: var(--gray-bkg) !important;
}

.invoice-total-input {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
}

.invoice-install-label {
    grid-column: 4/ 7;
    grid-row: 1 / 2;
    background-color: var(--gray-bkg) !important;
}

.invoice-install-input {
    grid-column: 7;
    grid-row: 1 / 2;
}

.invoice-deposit-label {
    grid-column: 1;
    grid-row: 2 / 4;
    background-color: var(--gray-bkg) !important;
}

.invoice-deposit-method-label {
    grid-column: 3;
    grid-row: 2 / 4;
    background-color: var(--gray-bkg) !important;
}

.invoice-deposit-input {
    grid-column: 2;
    grid-row: 2 / 4;
}

.invoice-deposit-method-cash-label {
    grid-row: 2;
    grid-column: 5;
    background-color: var(--gray-bkg) !important;
}

.invoice-deposit-method-c-c-label {
    grid-row: 3;
    grid-column: 5;
    background-color: var(--gray-bkg) !important;
}

.invoice-deposit-method-check-label {
    grid-row: 2;
    grid-column: 7;
    background-color: var(--gray-bkg) !important;
}

.invoice-deposit-method-auth-label {
    grid-row: 3;
    grid-column: 6/8;
}

.invoice-deposit-method-auth-input {
    grid-row: 3;
    grid-column: 6/8;
}

.invoice-cod-label {
    grid-column: 1;
    grid-row: 4/7;
    background-color: var(--gray-bkg) !important;
}

.invoice-cod-input {
    grid-column: 2;
    grid-row: 4/7;
}

.invoice-cod-method-label {
    grid-column: 3;
    grid-row: 4/7;
    background-color: var(--gray-bkg) !important;
}

.invoice-cod-method-cash-check-cc-label {
    grid-row: 4/6;
    grid-column: 4/8;
    background-color: var(--gray-bkg) !important;
}

.invoice-cod-method-fin-label {
    grid-row: 5;
    grid-column: 4/6;
    background-color: var(--gray-bkg) !important;
}

.invoice-cod-method-acc-label {
    grid-row: 4;
    grid-column: 4/8;
}

.invoice-cod-method-acc-input {
    grid-row: 4;
    grid-column: 4/8;
}

.invoice-cod-method-fin-terms-input {
    grid-row: 5;
    grid-column: 6/8;
}

.float-top-left {
    justify-self: start;
    align-self: flex-start;
    border: none !important;
    font-size: small;
    padding-left: .25rem;
}

.grid-float-text-right {
    text-align: right !important;
    padding-right: .25rem;
}

.grid-center-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.grid-right-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
}

.grid-format-static-money {
    font-size: x-large !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: .25rem;
    height: 100%;
    width: 100%;
}

.grid-format-static-money-lg {
    font-size: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: .25rem;
    height: 100%;
    width: 100%;
}

.grid-input-text-right {
    text-align: right !important;
}