#job-tracker-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#job-tracker-content {
    padding: 0 2.5%;
    background-color: var(--flat-gray-1);
    height: 100vw;
}

#go-back {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    width: fit-content;
    height: fit-content;
    align-items: center;
    padding: 4px 8px 4px 0;
    border-radius: var(--flat-inner-container-radius-sm);
    cursor: pointer;
}

#go-back:hover {
    background-color: var(--flat-gray-1);
}

#edit-customer-bar {
    width: 100%;
    background-color: var(--flat-gray-2);
    padding: var(--flat-inner-container-radius);
    display: flex;
    flex-direction: row;
}

#jt-navigator {
    display: flex;
    flex-direction: row;
    background-color: var(--flat-dark-red);
    margin-top: 1rem;
    border-radius: var(--flat-inner-container-radius-sm);
    padding: 0 10px;
    height: fit-content;
    justify-content: space-between;
}

.jt-navigator-button-inactive {
    background-color: inherit !important;
}