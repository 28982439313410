.contractor-page {
    padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--flat-gray-1);
}

.contractor-calendar {
    --rounded-stack-radius: 0.3em;
    text-align: center;
    background-color: var(--flat-white);
}

.contractor-calendar > div {
    /* Each row in the div calendar */
    display: flex;
    flex-direction: row;
    width: 100%;
}

.contractor-calendar > div > div {
    /* Each slot of the calendar */
    width: 100%;
}

.contractor-calendar > div:first-child {
    /* Header of calendar */
    --flat-font: 10pt;

    background-color: var(--flat-gray-5);
    color: var(--flat-white);
    font-size: var(--flat-font);
}

.contractor-calendar > div:first-child > div {
    margin: 4px 0;
}

.contractor-calendar > div:first-child > div:not(:last-child) {
    border-right: 1px white solid;
}

.contractor-calendar > div:not(:first-child) {
    /* Body of calendar */
    min-height: 3rem;
}

.contractor-calendar-day {
    z-index: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex: 1 1 0px;
}

.contractor-calendar-day > div:not(:first-child) {
    height: 2rem;
}

.contractor-calendar-day-block {
    box-sizing: border-box;
    padding: 1px 0;
}

.contractor-calendar-day-block > div {
    margin: 0 1px;
    border-radius: var(--rounded-stack-radius);
}

.contractor-calendar-stripe {
    border-top-left-radius: var(--rounded-stack-radius);
    border-bottom-left-radius: var(--rounded-stack-radius);
}

.contractor-calendar-day-block-container {
    padding: 2px;
}

.contractor-calendar-day-block-container > div {
    /* The text label for each thing on the appointment */
    user-select: none;
    white-space: nowrap;
    line-height: 1em;
    z-index: 2;
}

.unround-start {
    margin-left: 0px !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.unround-end {
    margin-right: 0px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.carpet-wood-gradient {
    background-image: linear-gradient(
        135deg,
        var(--calendar-carpet-color),
        var(--calendar-carpet-color) 50%,
        var(--calendar-wood-color) 50%
    );
}

.carpet-spc-gradient {
    background-image: linear-gradient(
        135deg,
        var(--calendar-carpet-color),
        var(--calendar-carpet-color) 50%,
        var(--calendar-spc-color) 50%
    );
}

.spc-wood-gradient {
    background-image: linear-gradient(
        135deg,
        var(--calendar-spc-color),
        var(--calendar-spc-color) 50%,
        var(--calendar-wood-color) 50%
    );
}

@keyframes flash-appointment {
    0%,
    40%,
    60% {
        background-color: white;
        color: black;
    }
    20%,
    80%,
    100% {
        background-color: gray;
        color: white;
    }
}

.appointment-date-default {
    color: black;
    background-color: white;
}

.appointment-date-today {
    background-color: rgb(83, 83, 83);
    color: white;
}

.flash-appointment-date {
    animation-name: flash-appointment;
    animation-delay: 350ms;
    animation-duration: 550ms;
    -webkit-animation-duration: 550ms;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

.darken-calendar-day {
    background-color: var(--flat-gray-4);
}

.blocked-calendar-day {
    --stripe-width: 3px;
    --stripe-spacing: 4px;
    --main-color: var(--flat-gray-2);
    --bkg-color: var(--flat-white); /* transparent */

    background: repeating-linear-gradient(
        -45deg,
        var(--main-color),
        var(--main-color) var(--stripe-width),
        var(--bkg-color) var(--stripe-width),
        var(--bkg-color) calc(var(--stripe-spacing) * 2)
    );
}

.border-calendar-day {
    outline: dashed red 2px;
    outline-offset: -2px;
    padding: 0;
}

.install-job-grid {
    --tile-border-radius: 0.5em;
    --tile-spacing: 0.5em;

    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--tile-spacing);
    padding: var(--tile-spacing);
}

.install-job-grid > div {
    border-radius: var(--tile-border-radius);
    padding: var(--tile-border-radius);
    background-color: #ffccd3 !important;
}

.disabled-grid-tile {
    filter: brightness(60%);
}

.material-location > div:nth-of-type(2) {
    margin-top: 0.5em;
}

.material-location-button {
    width: 100%;
    flex: 1;

    border-radius: 0.25em;
    background-color: rgb(149, 118, 175);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-selectable-btn {
    background-color: rgb(233, 206, 255) !important;
    color: #444 !important;
}

.faded-background-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: min(8rem, 20vh) !important;
    margin: auto;
    opacity: 0.1;
}

.flat-contractor-dashboard-header {
    width: 100%;
}

.contractor-appointment-list {
    display: grid;
    grid-template-columns: min-content auto;
}

.appointment-list-date {
    margin: 2px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: var(--flat-inner-container-radius);
    background-color: var(--flat-gray-3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.background-color-red {
    background-color: var(--flat-red);
}

.appointment-list-date > div {
    line-height: 1em;
}

.appointment-list-outer-item {
    border-radius: var(--flat-inner-container-radius);
    height: 44px;
    margin-bottom: 4px;
}

.appointment-list-outer-item > :first-child {
    border-top-left-radius: var(--flat-inner-container-radius);
    border-bottom-left-radius: var(--flat-inner-container-radius);
}

.appointment-list-item {
    display: grid;
    grid-template-columns: 1fr min-content;
    padding: 8px;
}

.appointment-list-item > div {
    white-space: nowrap;
    line-height: 1em;
    color: var(--flat-white);
}

.appointment-list-item > div:nth-child(3) {
    padding-top: 2px;
}

.appointment-blocked-item {
    --stripe-width: 6px;
    --stripe-spacing: 6px;
    --main-color: var(--flat-gray-4);
    --bkg-color: var(--flat-white); /* transparent */

    background: repeating-linear-gradient(
        -45deg,
        var(--main-color),
        var(--main-color) var(--stripe-width),
        var(--bkg-color) var(--stripe-width),
        var(--bkg-color) calc(var(--stripe-spacing) * 2)
    );

    display: flex;
    align-items: center;
    justify-content: center;
}

.appointment-blocked-item > div {
    background-color: var(--flat-gray-4);
    padding: 6px 16px;
    border-radius: 2px;
    text-transform: uppercase;
}

.contractor-job-page > div {
    margin-top: 4px;
    margin-bottom: 4px;
}

.contractor-job-page > .contractor-job-highlighted-action {
    margin-top: 12px;
}

.contractor-customer-bar {
    height: 3rem;
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr min-content;
    background-color: var(--flat-gray-5);
    border-radius: var(--flat-inner-container-radius);
    align-items: center;
}

.contractor-customer-bar > div {
    line-height: 1em;
}

.contractor-eta-bar {
    height: 3rem;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    background-color: var(--flat-gray-5);
    border-radius: var(--flat-inner-container-radius);
    align-items: center;
}

.contractor-job-highlighted-action {
    background-color: var(--flat-white);
    border-radius: var(--flat-inner-container-radius);
    margin: 16px;
    border: 2px var(--flat-gray-3) solid;
    display: flex;
    flex-direction: column;
}

.contractor-job-button-panel {
    display: grid;
    grid-template-columns: 1fr repeat(4, 0.5fr) 1fr;
    grid-template-rows: repeat(5, min-content);
    gap: 12px;
}

.contractor-job-button-panel > div {
    border-radius: var(--flat-inner-container-radius);
    background-color: var(--flat-white);
    min-height: 3rem;
    padding: var(--flat-inner-container-radius);
}

.contractor-job-horizontal-icon-button {
    display: flex;
    align-items: center;
    color: var(--flat-gray-5);
}

.contractor-job-horizontal-icon-button > :nth-child(2) {
    flex: 1;
    text-align: center;
}

.contractor-job-vertical-icon-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--flat-gray-5);
}

.contractor-job-vertical-icon-button > :nth-child(2) {
    flex: 1;
    text-align: center;
}

.contractor-job-materials {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.contractor-job-reverse-horizontal-icon-button {
    display: flex;
    align-items: center;
    color: var(--flat-gray-5);
}

.contractor-job-reverse-horizontal-icon-button > :first-child {
    flex: 1;
    margin-left: 4px;
}

.calendar-day-circle {
    border-radius: 2px;
    background-color: var(--flat-red);
    color: var(--flat-white);
}
