:root {
    --input-height: 30px;
    --label-size: 20pt;
    --minimize-button-width: 30px;
}

.chat-button-base {
    border: none;
    border-radius: 100%;
    padding: 15px;
}

.chat-button-active {
    background-color: var(--flat-red);
    box-shadow: 0 5px 14px #DC3B427F;
}

.chat-button-disabled {
    background-color: var(--flat-gray-4);
    box-shadow: 0 5px 14px #3232327F;
}

.chat-icon {
    color: white;
    font-size: 28px !important;
}

#chat-button-pin-right {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
}

.new-message-icon-base {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    cursor: default;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 5px 14px #3232327F;
}

.new-message-icon-standard {
    background-color: white;
    color: var(--flat-red);
}

.new-message-icon-inverted {
    background-color: var(--flat-red);
    color: white;
}


/* used to position the new message icon with the chat button */
.chat-button-new-message-icon-positioner {
    position: absolute;
    bottom: 70%;
    left: 70%;
}

.chat-window {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    width: 17.5rem;
    height: 25rem;
    border-radius: var(--flat-outer-container-radius);
}

.chat-window-free {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
}

.chat-window-contained {
    position: absolute;
    right: 2.5rem;
    bottom: 1rem;
}

.drag-handle {
    cursor: grab;
}

.drag-handle:active {
    cursor: grabbing;
}

.chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 10%;
    border-radius: var(--flat-outer-container-radius) var(--flat-outer-container-radius) 0 0;
}

#chat-close-button {
    width: var(--minimize-button-width);
    height: auto;
}

.chat-header-new-message-icon-container {
    position: relative;
    right: 8px;
    bottom: 15px;
    width: var(--minimize-button-width);
}

.chat-title-condensed {
    font-size: 10pt;
    margin-bottom: 2px;
    font-weight: bold;
}

.chat-title-full {
    font-size: 14pt;
    font-weight: bold;
}

.chat-subtitle {
    font-size: 10pt;
}

.action-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: .5rem;
    width: 100%;
    height: fit-content;
    background-color: var(--flat-gray-2);
    font-family: arial;
    font-size: 10pt;
}

.action-input {
    border: none;
    border-radius: 16px;
    background-color: white;
    padding: 5px;
    overflow: scroll;
    resize: none;
    height: var(--input-height);
    width: 85%;
    font-family: arial;
    font-size: 10pt;
    color: black;
    margin-bottom: 0.15rem;
}

.action-input:focus-visible {
    outline: none;
}

.action-box button:not(.send-message-button-base) {
    border-radius: 8px;
    background-color: white;
    font-family: arial;
    font-size: 10pt;
}

.chat-body {
    padding: var(--flat-outer-container-padding);
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.5rem;
    overflow-y: scroll;
    background-color: var(--flat-gray-2);
}

/* hide scroll bar in message body, but maintain scrollability */
.chat-body::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.chat-bubble {
    display: flex;
    align-items: center;
    max-width: 70%;
    width: fit-content;
    height: fit-content;
    border-radius: 8px;
    margin-top: 2px;
    padding: 0.2rem;
    background-color: white;
}

.chat-bubble pre {
    font-family: arial;
    font-size: 10pt;
    line-height: 12pt;
}

.chat-message-sender-label {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: var(--label-size);
    width: var(--label-size);
    background-color: white;
    color: var(--flat-red);
    font-family: arial;
    font-size: 10pt;
    font-weight: bold;
}

.message-row {
    display: flex;
    column-gap: 5px;
    justify-content: flex-end;
}

.message-row-same-party {
    flex-direction: row;
}

.message-row-different-party {
    flex-direction: row-reverse;
}

.message-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--flat-white);
    height: fit-content;
    padding: var(--flat-outer-container-padding);
    border-radius: var(--flat-outer-container-radius)
}

.chat-input {
    border: none;
    border-radius: 16px;
    background-color: var(--flat-gray-2);
    padding: 5px;
    overflow: scroll;
    resize: none;
    height: var(--input-height);
    width: 85%;
    font-family: arial;
    font-size: 10pt;
}

.chat-input:focus-visible {
    outline: none;
}

/* hide scroll bar in message body, but maintain scrollability */
.chat-input::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

/* hide scroll bar in new message body, but maintain scrollability */
.chat-input::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.send-message-button-base {
    border: none;
    border-radius: 100%;
    height: var(--input-height);
    width: var(--input-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.send-message-button-active {
    background-color: var(--flat-red);
}

.send-message-button-disabled {
    background-color: var(--flat-gray-4);
}

.send-icon {
    color: white;
    font-size: 20px !important;
    margin-bottom: 1px;
    margin-left: 1px;
}

#chat-drawer {
    position: fixed;
    bottom: 1%;
    right: 2.5%;
    z-index: 999;
}

#chat-drawer-closed-chats {
    margin-right: 1.5rem;
}

.closed-chat-button {
    display: inline-flex;
    flex-direction: column;
    gap: 7px;
    border: none;
    border-radius: 24px;
    padding: 12px;
    background-color: #ffffff;
    box-shadow: 0 5px 14px #3232327F;
    font-family: arial;
    font-size: 12pt;
    font-weight: bold;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    align-items: center;
}

.closed-chat-button p {
    margin-bottom: 0;
}

.closed-chat-button button {
    border: none;
    color: white;
    background-color: var(--flat-red);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    text-align: center;
    box-shadow: 0 5px 14px #3232327F;
}
