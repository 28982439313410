.weekly-install-calendar {
    display: grid;
    text-align: center;
    --weekly-column-width: calc(11rem + 2px);
    --card-base-color: #f6ebff;
    --card-highlight-color: #e6cdfa;
    --rounded-stack-radius: 0.6em;
    overflow-x: auto;
    overflow-y: auto;

    --flat-table-border-solid: 2px solid var(--flat-gray-3);
    --flat-table-border-dashed: 2px dashed var(--flat-gray-3);
}

.weekly-install-header {
    top: 0;
    position: sticky;
    align-self: flex-start;
    z-index: 1100;
    background-color: var(--flat-gray-5);
    height: 44px;
    text-align: center;
    color: white;
    /* border-right: 2px solid black; */
}

.weekly-install-header > div {
    margin: .5em 0; 
    border-left: solid white 1px;
}

.weekly-install-contractor {
    justify-self: flex-start;
    width: 100%;
    max-width: 100%;
    left: 0;
    position: sticky;
    background-color: white;
    z-index: 1000;
    /* border-right: 2px gray solid; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.weekly-base-slot {
    font-size: 0.9em;
    height: calc(5.5em + 3px);
    /* overflow: hidden; */
    position: relative;
    display: flex;
    flex-direction: column;
}

.weekly-base-slot-bkg {
    border-left: var(--flat-table-border-dashed);
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    filter: unset !important;
}

.selected-base-slot:not(.cont-unround-end):not(.cont-unround-start) .weekly-base-slot-bkg {
    border-left: unset;
    box-shadow: inset 0 0 4px 2px gold;
    z-index: inherit;
}

.selected-base-slot:not(.cont-unround-start) .weekly-base-slot-bkg {
    border-left: unset;
    box-shadow: inset 8px 0 4px -6px gold, inset 0 8px 4px -6px gold, inset 0 -8px 4px -6px gold;
    z-index: inherit;
}

.selected-base-slot:not(.cont-unround-end) .weekly-base-slot-bkg {
    border-left: unset;
    box-shadow: inset -8px 0 4px -6px gold, inset 0 8px 4px -6px gold, inset 0 -8px 4px -6px gold;
    z-index: inherit;
}

.selected-base-slot .weekly-base-slot-bkg {
    border-left: unset;
    box-shadow: inset 0 8px 4px -6px gold, inset 0 -8px 4px -6px gold;
    /* 0 0 6px 3px gold */
    z-index: inherit;
}

.card-indicator {
    position: absolute;
    width: 11rem;
    height: 100%;
    top: 0;
    right: 0;
    pointer-events: none;
}

.card-indicator:hover {
    box-shadow: inset 0 0 4px 2px #333;
}

.cont-rounded-stack { 
    padding: 3px;
    padding-left: 5px;
}

.cont-rounded-stack > div:nth-child(2) {
    border-top-left-radius: var(--rounded-stack-radius);
    border-top-right-radius: var(--rounded-stack-radius);
}

.cont-rounded-stack > div:nth-last-child(2) {
    border-bottom-left-radius: var(--rounded-stack-radius);
    border-bottom-right-radius: var(--rounded-stack-radius);
}

.cont-unround-start {
    padding-left: 0px !important;
}

.cont-unround-start > div:nth-child(2) {
    border-top-left-radius: 0;
}

.cont-unround-end {
    padding-right: 0px;
}

.cont-unround-start > div:nth-last-child(2) {
    border-bottom-left-radius: 0;
}

.cont-unround-end > div:nth-child(2) {
    border-top-right-radius: 0;
}

.cont-unround-end > div:nth-last-child(2) {
    border-bottom-right-radius: 0;
}

.card-text-label {
    z-index: 900;
    white-space: nowrap; 
    position: relative;
    text-align: left;
    color: var(--theme-main-text-color);
    font-family: arial;
}

.card-top-label {
    display: flex;
    font-weight: bold;
}

.card-top-label > div {
    padding-right: 4px;
}

.card-top-label > div:nth-child(even) {
    padding-right: 8px;
}

.label-for-single-card {
    flex-shrink: 1;
    overflow: hidden;
}

.ellipse-label-for-single-card {
    flex-shrink: 1;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bottom-label-for-single-card {
    overflow: hidden;
}