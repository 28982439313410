#email-list-container {
    width: 20rem;
    display: grid;
    grid-template-columns: 85% 15%;
    margin: 0 auto;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

#add-email-button {
    width: 100%;
    grid-column: 1 / 3;
}

.email-actions {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    justify-content: flex-start;
}