
.check-in-popup {
    background-color: #eeeeee;
    border: solid gray 2px;
    width: min(15rem, 80vw);
    height: 8rem;
    position: absolute;
    bottom: 4rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.clickable-text { 
    text-decoration-line: underline;
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}

.styled-label {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.styled-label > p:nth-child(1) {
    font-size: .75rem;
    line-height: .9rem;
}

.styled-label > p:nth-child(2) {
    padding-left: .25rem;
}


.desktop-appointment-row > td {
    text-align: center;
}

.desktop-appointment-header > th {
    text-align: center;
}

.highlight-appointment-row > td, .highlight-appointment-row > div {
    background-color: #00800033;
}

.mobile-appointment-row {
    width: 100%;
    margin-bottom: 1rem;
}

.mobile-appointment-row > div > :nth-child(1) {
    padding-right: .25rem;
    font-weight: lighter;
}

.desktop-appointments-grid {
    display: grid;
    column-gap: .25rem;
    row-gap: .5rem;
    grid-template-columns: 2fr 3fr 2fr 1fr 1fr 1fr 1fr;
    align-items: center;
}

.primary-desktop-row-item {
    font-size: 1.5rem;
}