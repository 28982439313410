#printable-pick-sheet-header {
    display: grid;
    grid-template-columns: .75fr .75fr .75fr 1fr .75fr .75fr .75fr;
    grid-template-rows: auto;
    grid-template-areas:
        ". . . title . . . "
        "cust-label customer . . inst-date-label inst-date weekday"
        "sah-label sah-num . . pick-date-label pick-date ."
    ;
}

#title {
    grid-area: title;
}

#cust-label {
    grid-area: cust-label;
}

#customer {
    grid-area: customer;
}

#inst-date-label {
    grid-area: inst-date-label;
}

#inst-date {
    grid-area: inst-date;
}

#weekday {
    grid-area: weekday;
}

#sah-label {
    grid-area: sah-label;
}

#sah-num {
    grid-area: sah-num;
}


#pick-date-label {
    grid-area: pick-date-label;
}

#pick-date {
    grid-area: pick-date;
}

#material-rows-container {
    display: flex;
    flex-direction: column;
    row-gap: .45rem;
}

.material-row {
    display: grid;
    grid-template-columns: .8fr .2fr;
    grid-template-rows: auto;
    column-gap: .45rem;
    height: 6rem;
}

#material-header-row {
    display: grid;
    grid-template-columns: .8fr .2fr;
    grid-template-rows: 1 fr 1 fr;
    column-gap: .45rem;
}

.header {
    height: fit-content;
    padding: .15rem;
    background-color: lightgray;
    text-align: center;
    border: 1px solid black;
}

.sub-header { 
    grid-template-columns: .125fr .25fr 1fr .25fr;
    height: fit-content;
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.sub-header div { 
    justify-content: center;
}

.material-sub-box {
    display: grid;
    grid-template-columns: .125fr .25fr 1fr .25fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
        "product bin style-color size"
        "product ref-num style-color size"
        "product ref-num cuts balance";
    border: 1px solid black;
    height: 100%;
}

.product-box {
    grid-area: product;
    border-right: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bin-box {
    grid-area: bin;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style-color-box {
    grid-area: style-color;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}

.size-box {
    grid-area: size;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ref-num-box {
    grid-area: ref-num;
    border-right: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cuts-box {
    grid-area: cuts;
    border-right: 1px solid black;
}

.balance-box {
    grid-area: balance;
}

.supplies-sub-box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: .34fr .66fr;
    border: 1px solid black;
    height: 100%;
}
