#cc-page {
    background-color: var(--flat-gray-1);
    width: 100%;
    min-height: 100vw;
}

#cc-page-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 1rem;
}

.cc-column-header-base {
    padding: var(--flat-outer-container-padding);
    text-transform: uppercase;
    border-radius: var(--flat-inner-container-radius-sm);
    padding: 4px 0;
    margin-bottom: 24pt;
    justify-content: center;
}

.cc-column-header-base p {
    color: white;
    font-weight: bold;
}

#cc-column-header-prospects {
    background-color: red;
}

#cc-column-header-pre-qualified {
    background-color: orange;
}

#cc-column-header-pre-qualified p {
    color: #001a33;
}

#cc-column-header-qualified {
    background-color: lightblue;
}

#cc-column-header-qualified p {
    color: #001a33;
}

#cc-column-header-reminder {
    background-color: lightgreen;
}

#cc-column-header-reminder p {
    color: #001a33;
}

#cc-column-header-recovery {
    background-color: gray;
}

.cc-card-worker-row {
    display: flex;
    gap: .25rem;
    align-items: center;
    padding: 3px 0.5em;
}

.cc-card-action-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0.5em;
}

.cc-card-action-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0.5em;
}

.customer-bar-prospects {
    border-bottom: var(--customer-bar-height) solid red;
}

.customer-bar-pre-qualified {
    border-bottom: var(--customer-bar-height) solid orange;
}

.customer-bar-qualified {
    border-bottom: var(--customer-bar-height) solid lightblue;
}

.customer-bar-reminder {
    border-bottom: var(--customer-bar-height) solid lightgreen;
}

.customer-bar-recovery {
    border-bottom: var(--customer-bar-height) solid gray;
}