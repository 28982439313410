.customer-match-result {
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    width: 50%;
}

.customer-match-result:nth-child(even) {
    border-left: none;
}

.customer-match-result:nth-child(n + 3) {
    border-top: none;
}