:root,
.flat-colors {
    --flat-gray-6: #323232;
    --flat-gray-5: #595959;
    --flat-gray-4: #bfbfbf;
    --flat-gray-3: #d1d2d4;
    --flat-gray-2: #e6e7e9;
    --flat-gray-1: #f2f1f6;

    --flat-white: white;
    --flat-red: #dc3b42;
    --flat-dark-red: #bc2229;
    --flat-orange: #ffb302;
    --flat-dark-orange: #ed7d31;
    --flat-yellow: #fce83a;
    --flat-cyan: #08ffff;
    --flat-blue: #0088ee;
    --flat-med-blue: #0070c0;
    --flat-green: #92d050;
    --flat-dark-green: #528f29;
    --flat-purple: #cc31fd;

    --flat-faded-blue: #d5f2ff;
    --flat-faded-red: #ea9999;

    --flat-wood-color: var(--flat-green);
    --flat-spc-color: #00B0F0; 
    --flat-carpet-color: var(--flat-red);

    --flat-outer-container-radius: 24px;
    --flat-inner-container-radius: 16px;
    --flat-inner-container-radius-sm: 8px;

    --flat-outer-container-padding: 10px;

    --theme-bkg-primary-color: var(--flat-gray-2);
    --theme-bkg-secondary-color: var(--flat-gray-3);

    --theme-main-text-color: var(--flat-gray-6);

    --flat-container-color: var(--flat-white);
    --theme-main-text-font-family: arial;
}

.flat-page {
    background-color: var(--flat-gray-1);
    width: 100%;
    height: 100vw;
}

.flat-page-content {
    width: 98vw; 
}

.flat-box-shadow {
    box-shadow: 0px 3px 25px 5px #5959597f;
}

.flat-container-box-shadow {
    box-shadow: 0px 5px 25px 0px #5959597f;
}

@media print {
    .flat-container-box-shadow {
        box-shadow: none;
    }
}

.flat-outer-container-padding {
    padding: var(--flat-outer-container-radius);
}

.flat-inner-container-padding {
    padding: var(--flat-inner-container-radius);
}

.flat-inner-container-padding-sm {
    padding: var(--flat-inner-container-radius-sm);
}

.flat-container {
    width: 100%;
}

.flat-outer-container {
    --min-container-size: calc(var(--flat-outer-container-radius) * 2);

    background-color: var(--flat-container-color);
    border-radius: var(--flat-outer-container-radius) !important;
    min-height: var(--min-container-size);
    min-width: var(--min-container-size);
}

.flat-section-header {
    height: var(--min-container-size);
    background-color: var(--theme-bkg-primary-color);
    color: var(--theme-main-text-color);
    border-top-left-radius: var(--flat-outer-container-radius);
    border-top-right-radius: var(--flat-outer-container-radius);

    padding-left: var(--flat-outer-container-radius);
    padding-right: calc(var(--flat-outer-container-radius) / 3);

    display: flex;
    align-items: center;
}

.flat-section-header-sticky {
    position: sticky;
    top: 0;
    z-index: 696969;
}

.flat-section-header-closed {
    border-bottom-left-radius: var(--flat-outer-container-radius);
    border-bottom-right-radius: var(--flat-outer-container-radius);
}

.flat-section-header-rectangle {
    border-radius: 0;
}

.flat-section-header-pad-right {
    padding-right: var(--flat-outer-container-radius);
}

.flat-section-content .invoice-document-header {
    width: 100%;
}

.flat-section-content .invoice-document-header td {
    border: none;
    padding-left: 0 !important;
    font-weight: bold;
}

.flat-section-content .invoice-document-header .invoice-content-name {
    font-weight: normal;
}

.flat-sub-section-header {
    height: var(--min-container-size);
    background-color: var(--flat-gray-1);
    border-top-left-radius: var(--flat-outer-container-radius);
    border-top-right-radius: var(--flat-outer-container-radius);

    padding-left: var(--flat-inner-container-radius);
    padding-right: calc(var(--flat-inner-container-radius) / 3);

    display: flex;
    align-items: center;
}

.flat-sub-section-content {
    border: 4px solid var(--flat-gray-1);
    border-top: none;
    border-bottom-left-radius: var(--flat-inner-container-radius);
    border-bottom-right-radius: var(--flat-inner-container-radius);
}

.flat-inner-container {
    width: "100%";
    border-radius: var(--flat-inner-container-radius);
    padding: var(--flat-inner-container-radius);
    background-color: var(--flat-container-color);
}

.flat-bordered-inner-container {
    width: "100%";
    padding: var(--flat-inner-container-radius);
    background-color: var(--flat-container-color);
    border: 3px solid var(--flat-gray-3);
    border-radius: var(--flat-inner-container-radius);
}

.flat-content {
    padding: 1rem;
}

.flat-h-bar {
    height: 2px;
    min-height: 2px;
    width: 100%;
    background-color: var(--flat-gray-3);
}

.flat-h-bar.thin {
    height: 1px;
}

.flat-button-base {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    padding: 8px 16px;
    font: 12px var(--theme-main-text-font-family);
    line-height: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 8px;

    margin: 0.25rem;
}

.flat-primary-major-button {
    background-color: var(--flat-red);
    color: var(--flat-white);
}

.flat-secondary-major-button {
    background-color: var(--flat-white);
    color: var(--flat-red);
    outline: 2px var(--flat-red) solid;
    outline-offset: -2px;
}

.flat-primary-minor-button {
    background-color: var(--flat-gray-5);
    color: var(--flat-white);
}

.flat-secondary-minor-button {
    background-color: var(--flat-white);
    color: var(--flat-gray-5);
    outline: 2px var(--flat-gray-5) solid;
    outline-offset: -2px;
}

.flat-gray-color {
    --theme-main-text-color: var(--flat-gray-5); 
    color: var(--theme-main-text-color);
}

.flat-gray-4 {
    --theme-main-text-color: var(--flat-gray-4); 
    color: var(--theme-main-text-color);
}

.flat-font-lg, .flat-font-md, .flat-font, .flat-font-sm, .flat-font-xsm {
    font-size: var(--flat-font-size);
    font-family: var(--theme-main-text-font);
    color: var(--theme-main-text-color);
} 

.flat-font-lg {
    --flat-font-size: 20pt;
    font-weight: bold;
}

.flat-font-md {
    --flat-font-size: 16pt;
}

.flat-font {
    --flat-font-size: 12pt;
}

.flat-font-sm {
    --flat-font-size: 10pt;
}

.flat-font-xsm {
    --flat-font-size: 8pt;
}

.flat-font-bold {
    font-family: var(--theme-main-text-font);
    font-weight: bold;
}

.flat-font-italic {
    font-family: var(--theme-main-text-font);
    font-style: italic;
}

.flat-font-upper {
    text-transform: uppercase;
}

.flat-gray-2-bkg:not(.selected-base-slot) .weekly-base-slot-bkg {
    background-color: var(--flat-gray-2);
}

.flat-horizontal-bar {
    width: 100%;
    background-color: var(--flat-gray-4);
    height: 2px;
}

.flat-thick-horizontal-bar {
    width: 100%;
    background-color: var(--flat-gray-4);
    height: 3px;
}

.flat-thin-horizontal-bar {
    width: 100%;
    background-color: var(--flat-gray-4);
    height: 1px;
}

.flat-lighter-bkg {
    --theme-main-text-color: var(--flat-gray-6);
    --theme-bkg-primary-color: var(--flat-gray-1);
    --theme-bkg-secondary-color: var(--flat-gray-2);
}

.flat-light-bkg {
    --theme-main-text-color: var(--flat-gray-6);
    --theme-bkg-primary-color: var(--flat-gray-3);
    --theme-bkg-secondary-color: var(--flat-gray-2);
}

.flat-dark-bkg {
    --theme-main-text-color: white;
    --flat-container-color: var(--flat-gray-5);
    --theme-bkg-primary-color: var(--flat-gray-5);
    --theme-bkg-secondary-color: var(--flat-gray-6);
}

.flat-darker-bkg {
    --theme-main-text-color: white;
    --flat-container-color: var(--flat-gray-6);
    --theme-bkg-primary-color: var(--flat-gray-5);
    --theme-bkg-secondary-color: var(--flat-gray-6);
}

.flat-red-theme-color {
    --theme-color: var(--flat-red);
    --theme-comp-text-color: var(--flat-white);
}
.flat-orange-theme-color {
    --theme-color: var(--flat-orange);
    --theme-comp-text-color: var(--flat-gray-6);
}
.flat-yellow-theme-color {
    --theme-color: var(--flat-yellow);
    --theme-comp-text-color: var(--flat-gray-6);
}
.flat-cyan-theme-color {
    --theme-color: var(--flat-cyan);
    --theme-comp-text-color: var(--flat-gray-6);
}
.flat-blue-theme-color {
    --theme-color: var(--flat-blue);
    --theme-comp-text-color: var(--flat-white);
}
.flat-green-theme-color {
    --theme-color: var(--flat-green);
    --theme-comp-text-color: var(--flat-gray-6);
}
.flat-purple-theme-color {
    --theme-color: var(--flat-purple);
    --theme-comp-text-color: var(--flat-white);
}
.flat-white-theme-color {
    --theme-color: var(--flat-white);
    --theme-comp-text-color: var(--flat-gray-6);
}

.flat-force-white-text {
    color: var(--flat-white) !important;
}

.flat-force-white-text .MuiSelect-icon {
    color: var(--flat-white) !important;
}

.flat-has-customer-rating-indicator.flat-dark-bkg .flat-customer-rating-indicator {
    color: var(--theme-color);
    outline: var(--theme-color) 2px solid;
    outline-offset: -2px;
}

.flat-has-customer-rating-indicator:not(.flat-dark-bkg) .flat-customer-rating-indicator {
    background-color: var(--theme-color);
    color: var(--theme-comp-text-color);
}

.flat-customer-rating-indicator {
    border-radius: 10pt;
    width: 2rem;
    text-align: center;
    font-weight: bold;
}

.wood-theme-color {
    --text-color: var(--flat-white);
    --background-color: var(--flat-green);
}

.lvp-theme-color {
    --text-color: var(--flat-black);
    --background-color: var(--flat-cyan);
}

.carpet-theme-color {
    --text-color: var(--flat-white);
    --background-color: var(--flat-red);
}

.flat-product-capability-indicator {
    border-radius: 10pt;
    width: 2rem;
    text-align: center;
    font-weight: bold;
    color: var(--text-color);
    background-color: var(--background-color);
    margin: 0 2px;
}

.disable-selection {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flat-navbar-logo {
    background-repeat: no-repeat;
    background-size: contain;
    height: 72px;
    width: 193px;
}

.flat-username-bubble {
    --bubble-size: 50px;

    height: var(--bubble-size);
    width: var(--bubble-size);
    background-color: var(--flat-gray-1);
    color: var(--flat-red);
    border-radius: 50%;
    line-height: var(--bubble-size);
    text-align: center;
}

.flat-username-bubble.empty-bubble {
    --stripe-width: 8px;
    --main-color: var(--flat-red);
    --bkg-color: var(--flat-gray-1); /* transparent */

    background: repeating-linear-gradient(
        45deg,
        var(--bkg-color),
        var(--bkg-color) var(--stripe-width),
        var(--main-color) var(--stripe-width),
        var(--main-color) calc(var(--stripe-width) * 2)
    );
}

.flat-username-label {
    font-size: 14pt;
    font-style: italic;
}

.flat-job-detail-table .flat-section-content {
    border: 4px solid var(--flat-gray-1);
    border-top: none;
    border-bottom-left-radius: var(--flat-inner-container-radius);
    border-bottom-right-radius: var(--flat-inner-container-radius);
}

.flat-job-detail-table table {
    width: 100%;
}

.flat-job-detail-table tr {
    border-top: 2px solid var(--flat-gray-3);
}

.flat-job-detail-table tr td {
    text-align: center;
    padding: 4px 0;
}

.flat-job-detail-table tr td:first-child {
    padding-left: 1rem;
}

.flat-job-detail-table tr td:last-child {
    padding-right: 1rem;
}

.flat-job-detail-table thead tr:first-child {
    background-color: var(--flat-gray-1);
    font-weight: bold;
}

.highlight-flat-text {
    background-color: var(--flat-red);
    color: var(--flat-white);
    border-radius: 8px;
    font-weight: bold;
    padding: 2px 10px;
}

.flat-date-picker {
    background-color: var(--theme-bkg-primary-color);
    color: var(--theme-main-text-color);
    border: none;
    width: 10rem;
}

.flat-date-picker-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-main-text-color);
}

.flat-label-base {
    font-family: var(--theme-main-text-font-family);
    text-align: left;
    padding: 0;
    text-transform: uppercase;
}

.flat-label-primary-sm {
    font-weight: 600;
    color: var(--flat-gray-4);
    font-size: 10pt;
}

.flat-label-primary {
    font-weight: 600;
    color: var(--flat-gray-4);
    font-size: 12pt;
}

.flat-label-primary {
    margin: 0;
}

.flat-label-bold {
    font-weight: 750;
    color: black;
    font-size: 12pt;
}

.flat-table tr {
    line-height: 1.75rem;
}

.flat-input {
    border: none;
    border-bottom: 2px solid darkgray;
    font-family: var(--theme-main-text-font-family);
    padding: 0 2px 0 2px;
}

.flat-input-error {
    border-bottom: 2px solid red  !important;
}

.flat-input-disabled {
   color: var(--flat-gray-3);
}

.flat-input:hover {
    border-bottom: 2px solid black;
    box-shadow:  0 -1px inset black;
}

.flat-input-error:hover {
    border-bottom: 2px solid red;
    box-shadow:  0 -1px inset red;
}

.flat-input:focus-visible {
    outline: none;
}

.flat-textarea {
    border: 2px solid var(--flat-gray-4);
    border-radius: 8px;
    resize: none;
    padding: 8px;
    font-family: var(--theme-main-text-font-family);
}

.flat-textarea:hover {
    border: 2px solid black;
}

.flat-input::placeholder, .flat-textarea::placeholder {
    text-transform: uppercase;
    color: var(--flat-gray-4);
    font-weight: 500;
    font-size: 13pt;
}

.flat-horizontal-bar {
    width: 100%;
    background-color: var(--flat-gray-1);
    height: 4px;
}

.flat-dialog-content {
    max-height: 50vh;
    min-height: 25vh;
    overflow: scroll;
}

#flat-customer-information-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 600px) {
    :root {
        --flat-inner-container-radius: 6px;
    }

    .flat-username-bubble {
        --bubble-size: 40px;
    }

    .flat-navbar-menu-button {
        max-height: 40px;
        max-width: 40px;
    }

    .flat-navbar-logo {
        height: 36px;
        width: 96px;
    }

    .flat-font-lg {
        --flat-font-size: 14pt;
    }

    .flat-font-md {
        --flat-font-size: 12pt;
        font-weight: bold;
    }

    .flat-font {
        --flat-font-size: 10pt;
    }
    .flat-font-sm {
        --flat-font-size: 8pt;
    }

    .flat-font-xsm {
        --flat-font-size: 6pt;
    }
}