@keyframes flash-red {
    from {
        background-color: white;
        color: var(--wof-red);
        transform: scale(1);
    }

    to {
        background-color: var(--wof-red);
        color: white;
        transform: scale(1.1);
    }
}

.flash-animation {
    background-color: white;
    color: var(--wof-red);
    animation-name: flash-red;
    animation-duration: 200ms;
    animation-iteration-count: 2;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

#service-for-room-dialog-error-text{
    color: var(--wof-red);
    border-radius: 1rem;
    padding: .1rem 1rem;
    margin: 0 1rem;
}