
.cell-shadow:hover {
    box-shadow: inset 0 0 4px 2px black;
}

.cell {
    color: "white";
    height: inherit;
    padding: 3px;
    background-clip: padding-box;
}

.selected-cell {
    box-shadow: inset 0 0 6px 3px gold;
    animation-name: pulse-box-shadow-gold;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.highlighted-cell {
    box-shadow: inset 0 0 6px 3px pink;
    animation-name: pulse-box-shadow-red;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.cell > div { height: 100%; }

@keyframes pulse-box-shadow-gold {
    from {
        box-shadow: inset 0 0 6px 3px gold;
    }

    to {
        box-shadow: inset 0 0 8px 5px gold;
    }
}

@keyframes pulse-box-shadow-red {
    from {
        box-shadow: inset 0 0 6px 3px red;
    }

    to {
        box-shadow: inset 0 0 8px 5px red;
    }
}

.render-over-parent {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.cell-border-side > td:not(:last-child) {
    border-right: 2px solid var(--flat-gray-3);
}

.cell-border-bottom > td {
    border-bottom: 2px dashed var(--flat-gray-3);
}