@media print {}

#seam-planner-page {
    background-color: aqua;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#seam-planner-mode-container {
    height: 3rem;
    display: flex;
    align-items: center;
}

#seam-planner-mode-container :not(:first-child) {
    margin-left: 1rem;
}

#room-seam-preview-container {
    flex: 1;
    background-color: green;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.fade-measure-details #measure-details > * {
    stroke: #08040240 !important;
    stroke-width: 1px;
}

.svg-packer-step-divider {
    stroke: #00000077;
    stroke-width: 1;
}

.test-cut-preview {
    fill: transparent;
    stroke: rgb(46, 46, 46);
    stroke-dasharray: 4 8;
    stroke-width: 1;
}

.test-cut-preview-bold {
    fill: transparent;
    stroke: rgb(46, 46, 46);
    stroke-dasharray: 4 8;
    stroke-width: 2;
}

.test-cut-clickable:hover {
    fill: #cd853f99;
}

.test-cut-faint-background {
    fill: #3d3d3d56;
}

.cut-selected {
    fill: gold !important;
}

#cut-packer-previewer > div:first-child {
    border: 4px var(--flat-gray-6) solid;
    border-right: none;
    box-sizing: content-box;
}

#cut-packer-previewer > div:nth-child(2) {
    position: absolute;
    top: 20px;
    border: 4px var(--flat-gray-6) solid;
    border-right: none;
    box-sizing: content-box;
}

#cut-packer-previewer {
    background-color: var(--flat-gray-1);
    position: relative;
}

.cut-block-list-item:hover {
    background-color: #00000033;
}

.cut-block-selected {
    background-color: #ffd700;
}

.cut-block-selected:hover {
    background-color: rgb(219, 186, 0);
}

.test-cut-waste-section {
    x: 0;
    y: 0;
    width: 100%;
    height: 100%;
    fill: url(#diagonalHatch);
}

.room-cut-number-1 {
    fill: #3eb48999;
}
.room-cut-number-1:hover {
    fill: #3eb48966;
}

.room-cut-number-2 {
    fill: #ff6ec799;
}
.room-cut-number-2:hover {
    fill: #ff6ec766;
}

.room-cut-number-3 {
    fill: #f5e29f99;
}
.room-cut-number-3:hover {
    fill: #f5e29f66;
}

.room-cut-number-4 {
    fill: #f9584b99;
}
.room-cut-number-4:hover {
    fill: #f9584b66;
}

.room-cut-number-5 {
    fill: #80e8e099;
}
.room-cut-number-5:hover {
    fill: #80e8e066;
}

.room-cut-number-6 {
    fill: #ffd12b99;
}
.room-cut-number-6:hover {
    fill: #ffd12b66;
}

.room-cut-number-7 {
    fill: #63af4399;
}
.room-cut-number-7:hover {
    fill: #63af4366;
}

.room-cut-number-8 {
    fill: #406e2699;
}
.room-cut-number-8:hover {
    fill: #406e2666;
}

.room-cut-number-9 {
    fill: #65849299;
}
.room-cut-number-9:hover {
    fill: #65849266;
}

.room-cut-number-10 {
    fill: #5f2e4c99;
}
.room-cut-number-10:hover {
    fill: #5f2e4c66;
}