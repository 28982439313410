:root {
    --profile-column-side-padding: 2rem;
}

#profile-grid {
    display: grid;
    grid-template-columns: 5fr 4fr;
}

#profile-grid-left-column {
    padding-right: var(--profile-column-side-padding);
    display: grid;
    grid-template-columns: 30% 70%;
    border-right: 1px solid var(--flat-gray-5);
    height: fit-content;
}

#profile-grid-right-column {
    padding-left: var(--profile-column-side-padding);
    height: fit-content;
}

#profile-grid p {
    margin: 0;
}

#profile-img-section {
    border-radius: var(--flat-inner-container-radius-sm);
    height: 12.5rem;
    width: 10rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

#profile-img-container {
    flex-grow: 1;
}

#profile-img-container img {
    width: 100%;
    height: 100%;
    box-shadow: 4px 4px 4px var(--flat-gray-6);
}

#profile-img-button {
    position: relative;
    bottom: 15%;
    left: 82%;
}

.profile-rating-pill {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 0 10px;
    border-radius: 999999px;  /* massive radius creates the pill shape */
    background-image: linear-gradient(to right, #FFF138, #EA4238);
    box-shadow: 3px 3px 4px var(--flat-gray-4);
    font-weight: bold;
}

.profile-left-column-table-container {
    display: flex;
    grid-column: 1 / 3;
    height: fit-content;
    margin-top: 1rem;
}

.profile-right-column-table-container {
    height: fit-content;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 15% 85%;
    width: 100%;
}

/* need an inner div because outer container needs to be flex rather grid to span columns */
.profile-left-column-table-container > div {
    display: grid;
    grid-template-columns: 25% 75%;
    width: 100%;
}

.profile-left-column-table-container > div > .thin-horizontal-bar {
    background-color: var(--flat-gray-4);
}

.profile-right-column-table-container > .thin-horizontal-bar {
    background-color: var(--flat-gray-4);
}

.profile-table-cell {
    border-bottom: 1px solid var(--flat-gray-4);
}

.profile-table-cell-inner {
    border-right: 1px solid var(--flat-gray-4);
}

.profile-table-label-cell {
    padding-left: calc(var(--profile-column-side-padding) / 2);
}

.profile-table-action-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px 0 5px;
    width: 100%;
}

.profile-table-action-cell > span {
    display: flex;
    align-items: center;
    font-style: italic;
    gap: 5px;
}

.profile-table-action-cell > span p {
    margin: 0;
}

/* for date text */
.profile-table-date-cell {
    text-align: center;
    color: var(--flat-gray-3);
}

/* for the + button row */
.profile-table-add-row {
    display: flex;
    justify-content: flex-end;
    padding: 2px 5px 2px 0;
}
/* for the file upload button row */
.file-upload-row {
    display: flex;
    grid-column: 1 / 4;
    padding-left: calc(var(--profile-column-side-padding) / 2);
}

.profile-quantity-cell {
    width: 2.3rem;
    text-align: right;
}

.helper-subtable {
    padding: 0.5rem 0;
}

.helper-subtable p {
    margin: 0;
}

.worker-status-icon {
    height: 20px;
    width: 20px;
    border-radius: 100%;
}

.worker-status-icon-active {
    background-color: var(--flat-green);
}

.worker-status-icon-inactive {
    background-color: gray;
}

#profile-rating-parameter-history {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

#profile-rating-parameter-history div:not(:first-of-type) {
    border-top: 1px solid var(--flat-gray-4);
    padding-top: 1rem;
}

#contractor-service-area > .thin-horizontal-bar {
    background-color: var(--flat-gray-4);
}