/* #region Old SS */

.ss-product-type-button {
    white-space: nowrap;
    overflow: hidden;
    height: 2rem;
}

.ss-product-style-button {
    white-space: nowrap;
    overflow: hidden;
    height: 2rem;
    margin: .5rem 0;
    padding-top: .5rem;
}

.ss-financing-div-spacing {
    margin: 1rem 0 .25rem 0;
    height: 5em;
    width: 100%;
}

.ss-your-price-margin {
    margin-top: 2rem;
}

.ss-monthly-payment-label {
    text-align: center;
    padding-top: .5rem;
}

.ss-button-along-bottom-of-page {
    margin-bottom: 1rem !important;
}

#no-quote-btn {
    position: fixed;
    bottom: 1.5rem;
    left: 1.5rem;
    width: fit-content;
}

/* #endregion */

.ss-top-gallery {
    display: grid;
    grid-auto-flow: column;
    max-width: 69vw;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    margin-bottom: .5vh;
}

.ss-bottom-gallery {
    display: grid;
    grid-auto-flow: column;
    max-width: 92vw;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    padding: 2vh 0;
    box-sizing: border-box;
}

.ss-gallery-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1.5em;

    --quote-card-width: 20vw;
}

.ss-top-gallery .ss-gallery-item {
    min-width: var(--quote-card-width);
}

.ss-bottom-gallery .ss-gallery-item {
    height: min(100%, 9em);
    min-width: 18vw;
    background-color: rgb(231, 231, 231);
    border-radius: .5em;
    box-shadow: 0 0 .5em .5em rgba(0, 0, 0, 0.3);
}

.ss-quote-card {
    border-radius: .5em;
    background-color: rgb(241, 241, 241);
    box-shadow: 0 0 .5em .5em rgba(0, 0, 0, 0.3);
}

.ss-snaps-inline {
    scroll-snap-type: inline mandatory;
}

.ss-snaps-inline > * {
    scroll-snap-align: start;
}

.ss-inverted-fill-card {
    width:100%;
    background-color: rgb(48, 48, 48);
    color:white
}

.ss-top-left-floating-menu {
    position: absolute; 
    left: 0; 
    top: 0;
    margin: 1em;
    display: flex;
    flex-direction: column;
}

.ss-page-color {
    background-color: #334080;
}

.ss-top-left-floating-menu > * {
    margin: .25em 0 !important;
    border-radius: 100% !important;
    padding: .55em .75em !important; 
    min-width: 2.25em;
}

.ss-bottom-right-floating-button {
    position: absolute; 
    right: 7rem; 
    bottom: 0;
    margin: 1em;
    display: flex;
    flex-direction: column;
}

.ss-bottom-right-floating-button > * {
    font-size: 1.25em !important;
    margin: .25em 0 !important;
    border-radius: 100% !important;
    padding: .55em .75em !important; 
    min-width: 2.25em;
}

.ss-quote-card > .ss-render-area-label-row:nth-of-type(1) {
    border-radius: .5em .5em 0 0;
}

.ss-render-area-label-row {
    height: 2em;
    display: flex;
    flex-direction: row;
    padding: .25em;
    align-items: center;
    border-bottom: solid .1em #00000044;
    box-sizing: content-box;
    background-color: rgb(241, 37, 37);
    color: white;
}

.ss-render-area-label-row :not(:nth-child(2)) {
    padding: .1em .35em !important;
    min-width: 0 !important;
}

.ss-render-area-label-row :nth-child(2) {
    margin: 0 .25em !important;
    flex:1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ss-render-area-style-row {
    height: 2.5em;
    align-items: center;
    width: 100%;
}

.ss-render-area-style-row > * {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ss-config-breakdown {
    flex: 1;
    display: grid;
    grid-template-columns: minmax(0, auto) minmax(0, min-content) min-content;
    grid-template-rows: 2em 2em 2em 2em 2em;
    margin: 0 .25em;
    align-items: end;
    max-width: calc(var(--quote-card-width ) - .5em);
    overflow: hidden;
}

.ss-shade {
    background-color: rgb(163, 163, 163) !important;
    color:rgb(48, 48, 48) !important;
}

.ss-shade-inverted {
    background-color: rgb(88, 88, 88) !important;
    color:rgb(211, 211, 211) !important;
}

.ss-shade-btn {
    background-color: rgb(163, 163, 163) !important;
    color:rgb(48, 48, 48);
}

.ss-shade > .ss-render-area-label-row {
    background-color: rgb(179, 27, 27) !important;
}

.ss-shade-text {
    color:rgb(73, 73, 73) !important;
}
/* Selects the elements that needs to span two columns */
.ss-config-breakdown > *:nth-last-child(2n + 5) {
    grid-column: 1 / 3;
    min-width: 0;
    max-width: 100%;
}

.ss-option-header {
    text-align: center;
    font-size: 1.25em;
    color: white;
}