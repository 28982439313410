:root {
    --table-separator-width: 3px;
}

#sp-service-area {
    margin-bottom: 0.5rem;
}

#sp-service-area > .thin-horizontal-bar {
    background-color: var(--flat-gray-4);
}

#sp-performance-summary {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

#sp-performance-summary > .thin-horizontal-bar {
    background-color: var(--flat-gray-4);
}

.sp-summary-table-pseudo-header {
    border-bottom: 2px solid var(--flat-gray-4);
}

.sp-summary-table tr:not(:last-of-type):not(.sp-summary-table-pseudo-header) {
    border-bottom: 1px solid var(--flat-gray-4);
}

.sp-summary-table tr td:not(:last-of-type) {
    border-right: 1px solid var(--flat-gray-4);
}

.sp-summary-table td, .sp-summary-product-type-statistics-table td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.sp-summary-table td:not(:first-of-type) {
    text-align: right;
}

#sp-summary-table-1-2-3-container {
    display: flex;
    flex-direction: row;
    width: 80%;
}

#sp-summary-table-1 {
    border-top: 2px solid var(--flat-gray-4);
    border-bottom: none;
    border-left: 1px solid var(--flat-gray-4);
    border-right: var(--table-separator-width) solid var(--flat-gray-4);
    width: 45%;
}

#sp-summary-table-2 {
    border-top: 2px solid transparent;
    border-bottom: none;
    border-left: none;
    width: 30%;
}

#sp-summary-table-2 tr:not(:first-of-type) > td:last-of-type {
    border-right: var(--table-separator-width) solid var(--flat-gray-4);
}

#sp-summary-table-3 {
    width: 25%;
    border-bottom: none;
    border-top: 2px solid transparent;
}

#sp-summary-table-3 tr td:last-of-type:not(.sp-summary-table-row-spacer) {
    border-right: var(--table-separator-width) solid var(--flat-gray-4);
}

#sp-summary-table-4-container {
    display: flex;
    flex-direction: row;
    width: 20%;
    margin-top: 2px;
}

#sp-summary-table-4 {
    width: 100%;
    border: none;
}

#sp-summary-table-4 tr td:last-of-type:not(.sp-summary-table-row-spacer) {
    border-right: 1px solid var(--flat-gray-4);
}

#sp-summary-table-4 tr:first-of-type {
    border-bottom: 2px solid transparent;
}

/* allows an empty row on top of 3 rightmost tables */
.sp-summary-table-row-spacer {
    visibility: hidden;
    border: none;
}

.sp-summary-red-text {
    color: var(--flat-dark-red)
}

.sp-summary-blue-text {
    color: var(--flat-med-blue)
}

.sp-summary-product-type-statistics-table {
    border: 1px solid var(--flat-gray-4);
    border-top: 2px solid var(--flat-gray-4);
    width: fit-content;
}

.sp-summary-product-type-statistics-table thead {
    border-bottom: 2px solid var(--flat-gray-4);
}

.sp-summary-product-type-statistics-table td:not(:last-of-type) {
    border-right: 1px solid var(--flat-gray-4);
}

.sp-summary-product-type-statistics-table tr:not(thead > tr):not(:first-of-type) {
    border-top: 1px solid var(--flat-gray-4);
}

.sp-summary-product-type-statistics-table tr td:not(:first-of-type) {
    text-align: right;
    vertical-align: middle;
}