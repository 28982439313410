.script-tab:not(:last-of-type) {
    border-bottom: 1px solid lightgray;
}

#active-script-tab {
    background-color: lightgray;
}

#tab-container {
    border: 1px solid black;
    margin-top: 2.5rem !important;
}

#script-text-input {
    width: 35rem;
    border: 1px solid black;
    max-height: 27rem;
    overflow-y: auto !important;
    padding: .25rem;
}