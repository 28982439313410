.rc-column {
    display: flex;
    flex-direction: column;
    flex:1;
    padding:.25em;
}

.small-text {
    font-size: .75em;
    
}

.gray-text {
    color: var(--card-gray)
}

.option-select {
    margin: 0 .25em;
    font-size: .75em;
    width: 6.66em;
}

.date-picker {
    max-width: 6.66em;
    font-size: .75em;
}

/* #region Header Selectors */

.rc-column-header-base {
    padding: var(--flat-outer-container-padding);
    text-transform: uppercase;
    border-radius: var(--flat-inner-container-radius-sm);
    padding: 4px 0;
    margin-bottom: 24pt;
    text-align: center;
}

.rc-column-header-base p {
    color: white;
    font-weight: bold;
}

#rc-column-header-hot {
    background-color: red;
}

#rc-column-header-in-progress {
    background-color: orange;
}

#rc-column-header-in-progress p {
    color: #001a33;
}

#rc-column-header-todays-follow-up {
    background-color: lightblue;
}

#rc-column-header-todays-follow-up p {
    color: #001a33;
}

#rc-column-header-upcoming {
    background-color: gray;
}

.rc-chip-holder {
    flex-grow: 1;
    padding: 0 .2em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.rc-quote-number {
    color: var(--card-light-blue);
    font-size: .75em;
}

/* #endregion */

/* #region Customer Bar Selectors */

.rc-customer-bar-icon {
    grid-row: 1;
    grid-column: 1;
    margin: auto;
}

/* #endregion */
/* #region Unclaimed Card */
.unclaimed-card-content {
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unclaimed-card-content > button {
    background-color: var(--card-red) !important;
}
/* #endregion */


/* #region Claimed Card */
.claimed-card-content {
    height: 10em;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr 1fr 1fr auto 1fr;
    grid-template-rows: 1fr auto auto auto auto 1fr;
}

/* #region Salesperson */

.claimed-sp-icon{
    grid-row: 1;
    grid-column: 1;
    width:1.5em;
    height:1.5em;
}

.claimed-sp {
    grid-row: 1;
    grid-column: 2 / 4;
    white-space: nowrap;
    font-size: .9em;
    text-align: left;
}

.claimed-sp-date {
    grid-row: 1;
    grid-column: 4;
}

.claimed-price {
    grid-row: 1;
    grid-column: 6;
}

/* #endregion */

/* #region Reason Row */
.claimed-rc-icon {
    grid-row: 2;
    grid-column: 1;
}

.claimed-reason-label {
    grid-row: 2;
    grid-column: 2;
    text-align: left;
}

.claimed-reason-options {
    grid-row: 2;
    grid-column: 3;
}

.claimed-reason-date {
    grid-row: 2;
    grid-column: 4;
}

.claimed-journal-icon {
    grid-row: 2;
    grid-column: 5;
}

/* #endregion */

/* #region Last Step Row */
.claimed-last-label {
    grid-row: 3;
    grid-column: 2;
    white-space: nowrap;
    text-align: left;
}

.claimed-last-options {
    grid-row: 3;
    grid-column: 3;
}

.claimed-last-date {
    grid-row: 3;
    grid-column: 4;
}
/* #endregion */

/* #region Action Row */
.claimed-action-label {
    grid-row: 4;
    grid-column: 2;
    white-space: nowrap;
    text-align: left;
}

.claimed-action-options {
    grid-row: 4;
    grid-column: 3;
    display: flex;
    justify-content: space-between;
    padding: 0 .25em;
}

.claimed-action-date {
    grid-row: 4;
    grid-column: 4;
}

.claimed-unlock-button {
    grid-row: 4;
    grid-column: 5;
    display: flex;
    justify-content: center;
}
/* #endregion */

/* #region Next Step Row */
.claimed-next-label {
    grid-row: 5;
    grid-column: 2;
    white-space: nowrap;
    
    text-align: left;
}

.claimed-next-options {
    grid-row: 5;
    grid-column: 3;
}

.claimed-next-date {
    grid-row: 5;
    grid-column: 4 / 6;
}

.next-date-picker-styles {
    width: 100%;
    font-size: .75em;
}
/* #endregion */

.claimed-send-button {
    grid-row: 6;
    grid-column: 6;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;    
}

/* #region Otherable Type Box */
.other-reason-input {
    margin: 0 .25em;
    font-size: .75em;
    width: 6.66em;
    display: inline-block;
    color: #666666;
    text-indent: 4px;
    border: 1px solid #2d2d2d;
    border-radius: 2px;
    
  }
  
  .other-reason-position {
    position: relative;
  }
  
  .other-reason-position span {
    position: absolute;
    right: .5em;
    cursor: pointer;
  }

  .disabled-reason {
    cursor: default !important;
    visibility: hidden;
  }
/* #endregion */

/* #endregion */

/* #endregion  */