.financing-section-option {
    min-width: fit-content;
    height: fit-content;
    padding: 0.25rem;
    border-radius: 0.2rem;
    cursor: pointer;
}

#financing-section-option-selected {
    border: 2px solid var(--wof-red);
}

.financing-section-option-enabled {
    border: 2px solid black;
}

.financing-section-option-disabled-appearance {
    border: 2px solid gray;
    color: gray;
    cursor: default;
}