/**
 * FIXME: for some reason, an iFrame kept getting drawn on the change order page
 * solution from: https://github.com/facebook/create-react-app/issues/11771#issuecomment-1017125835
 * gets fixed, let's hide the problem and not address it
 */
body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
    display: none;
}

:root {
    --wof-red: #e42040;
    --wof-red-dark: #c51734;
    --wof-secondary: #263238;
    --wof-secondary-dark: #11181b;
    --accept-color: #007979;
    --reject-color: #8b0000;
    --light-reject-color: #be0000;
    --gray-bkg: #ddddddff;
    font-size: large;

    --area-none-color: lightgray;
    --area-1-color: #ffaaaa;
    --area-2-color: #aaffaa;
    --area-3-color: #aaaaff;
    --area-4-color: #ffffaa;
    --area-5-color: #aaffff;

    --area-none-color-dark: rgb(184, 184, 184);
    --area-1-color-dark: #e48b8b;
    --area-2-color-dark: #8be48b;
    --area-3-color-dark: #8b8be4;
    --area-4-color-dark: #e4e98b;
    --area-5-color-dark: #8be4e4;

    --calendar-carpet-color: #f59dbc;
    --calendar-spc-color: #5dc5ea;
    --calendar-wood-color: #2cb549;
    --calendar-service-color: #fbb040;
    --calendar-blocked-color: #adadad;

    --calendar-carpet-color-light: #f7d0de;
    --calendar-spc-color-light: #c0e0eb;
    --calendar-wood-color-light: #bce9c5;
    --calendar-service-color-light: #ebdac2;
    --calendar-blocked-color-light: #e0e0e0;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.darken-on-hover:hover {
    filter: brightness(80%);
}

.area-no-color #room-color,
.single-room.area-no-color #room-colors polygon,
#room-colors .area-no-color {
    fill: white !important;
    fill-opacity: 1;
    background-color: white;
}

.area-none #room-color,
.single-room.area-none #room-colors polygon,
#room-colors .area-none {
    fill: var(--area-none-color) !important;
    fill-opacity: 1;
    background-color: var(--area-none-color);
}

.area-1 #room-color,
.single-room.area-1 #room-colors polygon,
#room-colors .area-1 {
    fill: var(--area-1-color) !important;
    fill-opacity: 1;
    background-color: var(--area-1-color);
}

.area-2 #room-color,
.single-room.area-2 #room-colors polygon,
#room-colors .area-2 {
    fill: var(--area-2-color) !important;
    fill-opacity: 1;
    background-color: var(--area-2-color);
}

.area-3 #room-color,
.single-room.area-3 #room-colors polygon,
#room-colors .area-3 {
    fill: var(--area-3-color) !important;
    fill-opacity: 1;
    background-color: var(--area-3-color);
}

.area-4 #room-color,
.single-room.area-4 #room-colors polygon,
#room-colors .area-4 {
    fill: var(--area-4-color) !important;
    fill-opacity: 1;
    background-color: var(--area-4-color);
}

.area-5 #room-color,
.single-room.area-5 #room-colors polygon,
#room-colors .area-5 {
    fill: var(--area-5-color) !important;
    fill-opacity: 1;
    background-color: var(--area-5-color);
}

.area-none #step-color {
    fill: var(--area-none-color-dark);
    background-color: var(--area-none-color-dark);
}

.area-1 #step-color {
    fill: var(--area-1-color-dark);
    background-color: var(--area-1-color-dark);
}

.area-2 #step-color {
    fill: var(--area-2-color-dark);
    background-color: var(--area-2-color-dark);
}

.area-3 #step-color {
    fill: var(--area-3-color-dark);
    background-color: var(--area-3-color-dark);
}

.area-4 #step-color {
    fill: var(--area-4-color-dark);
    background-color: var(--area-4-color-dark);
}

.area-5 #step-color {
    fill: var(--area-5-color-dark);
    background-color: var(--area-5-color-dark);
}

#room-background {
    fill: #444 !important;
}

/* TODO: this looks horrible when it's here, but we need something to add a border to non-active text field */
.MuiTextField-root {
    border-bottom: 1px solid rgb(171, 171, 171) !important;
}

.MuiTextField-root:hover {
    border-bottom: 1px solid black !important;
}

.MuiSelect-root {
    border-bottom: 1px solid black !important;
}

@media print {
    div {
        -webkit-print-color-adjust: exact;
    }

    .pagebreak {
        display: block;
        break-before: always;
        page-break-before: always;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.hide-input-button input[type="number"]::-webkit-inner-spin-button,
.hide-input-button input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* font-size: calc(10px + 2vmin); */
    /* font-size: large; */
    color: white;
}

.App-link {
    color: #61dafb;
}

.link-to-button:hover {
    background-color: var(--wof-secondary-dark);
    color: white;
}

.link-to-button {
    text-decoration: none;
    color: white;
    background-color: var(--wof-secondary);
    font-size: 0.875rem;
    padding: 6px 16px;
    border-radius: 5px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.modal-content {
    background-color: white;
}

.color-wof-red {
    color: var(--wof-red);
}

.color-red {
    color: red !important;
}

.color-gray {
    color: lightgray;
}

.color-gray-important {
    color: lightgray !important;
}

.color-dgray-important {
    color: gray !important;
}

.font-size-larger {
    font-size: large;
}

.job-header-styles {
    min-height: 8vh;
    height: 8vh;
    width: 100%;
    display: flex;
    border-bottom: solid 2px #e42040;
    align-items: center;
    overflow: hidden;
    background-color: white;
}

.job-header-cust-details-div {
    height: 8vh;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
}

.job-header-cust-details-line {
    font-size: 2.5vh !important;
    line-height: 1 !important;
}

.unset-border {
    border: unset !important;
}

.table-borders td,
.table-borders th {
    border: 1px solid black;
    border-collapse: collapse;
}

.wof-secondary-color {
    color: var(--wof-secondary) !important;
}

.underline-gray {
    border-bottom: 1px solid gray;
}

.letter-spacing-1px {
    letter-spacing: 1px;
}

.font-weight-500 {
    font-weight: 500;
}

.less-bold-text, .font-weight-600 {
    font-weight: 600;
}

.bold-text {
    font-weight: bold;
}

.italic-text {
    font-style: italic;
}

.line-through {
    text-decoration: line-through !important;
}

.underline-text {
    text-decoration: underline;
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right !important;
}

.vertical-align-top {
    vertical-align: top;
}

.gray-bkg {
    background-color: var(--gray-bkg) !important;
}

.dark-mid-gray-bkg {
    background-color: #888888 !important;
}

.mid-gray-bkg {
    background-color: #dddddd !important;
}

.faint-gray-bkg {
    background-color: #eeeeee;
}

.white-bkg {
    background-color: #ffffff;
}

.visibility-hidden {
    visibility: hidden !important;
}

.visibility-collapse {
    visibility: collapse;
}

.display-none {
    display: none;
}

.solid-border {
    border: black solid 1px;
}

.solid-border-right {
    border-right: black solid 1px;
}

.solid-border-bottom {
    border-bottom: black solid 1px;
}

.solid-vertical-border {
    border-top: black solid 1px;
    border-bottom: black solid 1px;
}

.rounded-border {
    border-radius: 8px;
}

.border-radius-5px {
    border-radius: 5px;
}

.border-vertical-double {
    border-top-style: double;
    border-bottom-style: double;
}

.border-none {
    border: none;
}

.nocolor-horizontal-bar {
    width: 100%;
    height: 1px;
}

.horizontal-bar {
    width: 100%;
    background-color: black;
    height: 2px;
}

.thick-horizontal-bar {
    width: 100%;
    background-color: black;
    height: 3px;
}

.thin-horizontal-bar {
    width: 100%;
    background-color: black;
    height: 1px;
}

.vertical-bar {
    height: auto;
    background-color: black;
    width: 1px;
}

.sub-horizontal-bar {
    width: 100%;
    background-color: darkgray;
    height: 1px;
}

.clickable {
    cursor: pointer;
}

.not-selectable {
    /* Chrome */
    user-select: none;
    /* Firefox */
    -moz-user-select:none;
    /* Safari */
    -webkit-user-select: none;
    /* Mobile Safari  */
    -webkit-touch-callout: none;
}

.fill-viewport {
    width: 100vw;
    height: 100vh;
}

.fill-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.fit-content-height {
    height: fit-content;
}

.fit-content-width {
    width: fit-content;
}

.fit-content {
    height: fit-content;
    width: fit-content;
}

.fill-height {
    height: 100%;
}

.row-primary {
    background-color: white;
}

.row-secondary {
    background-color: lightgrey !important;
}

.centered {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-centered {
    align-items: center;
}

.align-items-flex-start {
    align-items: flex-start;
}

.align-items-flex-end {
    align-items: flex-end;
}

.align-items-baseline {
    align-items: baseline;
}

.flex-grow {
    flex: 1;
}

.flex-column {
    display: flex !important;
    flex-direction: column;
}

.flex-column {
    display: flex !important;
    flex-direction: column;
}

.flex-row {
    display: flex !important;
    flex-direction: row;
}

.flex-row-reverse {
    display: flex !important;
    flex-direction: row-reverse;
}

.flex-row-wrap {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-column-reverse {
    display: flex !important;
    flex-direction: column-reverse;
}

.flex-space-end {
    justify-content: flex-end;
}

.flex-space-evenly {
    justify-content: space-evenly;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-space-around {
    justify-content: space-around;
}

.flex-row-center {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-flex-end {
    justify-content: flex-end;
}

.justify-content-flex-start {
    justify-content: flex-start;
}

.flex-column-center {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.inline-flex-column {
    display: inline-flex;
    flex-direction: column;
}

.flex-gap-xxsm {
    gap: 0.25rem;
}

.flex-gap-xsm {
    gap: 0.5rem;
}

.flex-gap-sm {
    gap: 1rem;
}

.flex-gap-md {
    gap: 2rem;
}

.flex-gap-lg {
    gap: 5rem;
}

.flex-row-gap-xsm {
    row-gap: 0.5rem;
}

.flex-row-gap-sm {
    row-gap: 1rem;
}

.flex-row-gap-md {
    row-gap: 2rem;
}

.flex-column-gap-md {
    column-gap: 2rem;
}

.align-self-flex-start {
    align-self: flex-start;
}

.align-self-flex-end {
    align-self: flex-end;
}

.align-self-center {
    align-self: center;
}

.align-self-end {
    align-self: end;
}

.justify-self-center {
    justify-self: center;
}

.justify-self-flex-end {
    justify-self: flex-end;
}

.grid-30-70 {
    display: grid;
    grid-template-columns: 30% 70%;
}

.grid-30-20-50 {
    display: grid;
    grid-template-columns: 30% 20% 50%;
}

.grid-15-50-35 {
    display: grid;
    grid-template-columns: 15% 50% 35%;
}

.grid-10-45-45 {
    display: grid;
    grid-template-columns: 10% 45% 45%;
}

.grid-10-90 {
    display: grid;
    grid-template-columns: 10% 90%;
}

.grid-50-50 {
    display: grid;
    grid-template-columns: 50% 50%;
}

.grid-40-60 {
    display: grid;
    grid-template-columns: 40% 60%;
}

.grid-60-40 {
    display: grid;
    grid-template-columns: 60% 40%;
}

.grid-75-25 {
    display: grid;
    grid-template-columns: 75% 25%;
}

.grid-25-50-25 {
    display: grid;
    grid-template-columns: 25% 50% 25%;
}

.grid-33-33-33 {
    display: grid;
    grid-template-columns: 33% 34% 33%;
}

.grid-colspan-1-3 {
    display: flex;
    grid-column: 1 / 3;
}

.grid-colspan-1-4 {
    display: flex;
    grid-column: 1 / 4;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.error-number-format {
    border-bottom-color: var(--light-reject-color);
    border-bottom-width: 5px;
}

.submit-button {
    background-color: var(--accept-color) !important;
    color: white !important;
}

.accept-text {
    color: var(--accept-color) !important;
}

.error-bkg {
    background-color: var(--light-reject-color) !important;
}

.error {
    background-color: black !important;
    background-image: linear-gradient(45deg, magenta 25%, transparent 25%),
        linear-gradient(135deg, magenta 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, magenta 75%),
        linear-gradient(135deg, transparent 75%, magenta 75%);
    background-size: 25px 25px;
    /* Must be a square */
    background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px;
}

.error-text {
    color: red;
}

.error-text-important {
    color: red !important;
}

.warning-text {
    color: orange;
}

.success-text {
    color: green;
}

.success-text-important {
    color: green !important;
}

.no-opacity {
    opacity: 0;
}

.money::before {
    content: "$";
}

.cancel-button {
    /* Must be half of one side of the square */
    color: white !important;
    background-color: var(--reject-color) !important;
}

.margin-v-center {
    margin: auto 0;
}

.flex-1 {
    flex: 1 !important;
}

.margin-auto {
    margin: auto;
}

.margin-xxsm {
    margin: 0.25rem !important;
}

.margin-xsm {
    margin: 0.5rem !important;
}

.margin-sm {
    margin: 1rem !important;
}

.margin-md {
    margin: 3rem !important;
}

.margin-lg {
    margin: 5rem !important;
}

.children-margin-top-xxsm > * {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
}

.margin-side-xxsm {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}

.margin-side-xsm {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.margin-side-sm {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.margin-side-xmd {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}

.margin-vertical-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.margin-vertical-xsm {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
}

.margin-vertical-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.margin-top-xsm {
    margin-top: 0.5rem !important;
}

.margin-left-xsm {
    margin-left: 0.5rem !important;
}

.margin-left-sm {
    margin-left: 1rem !important;
}

.margin-left-md {
    margin-left: 2rem !important;
}

.margin-left-xmd {
    margin-left: 3rem !important;
}

.margin-right-none {
    margin-right: 0 !important;
}

.margin-right-xxsm {
    margin-right: 0.25rem !important;
}

.margin-right-xsm {
    margin-right: 0.5rem !important;
}

.margin-right-sm {
    margin-right: 1rem !important;
}

.margin-right-md {
    margin-right: 2rem !important;
}

.margin-right-xmd {
    margin-right: 3rem !important;
}

.margin-top-sm {
    margin-top: 1rem !important;
}

.margin-top-md {
    margin-top: 2rem !important;
}

.margin-bottom-xsm {
    margin-bottom: 0.5rem !important;
}

.margin-bottom-sm {
    margin-bottom: 1rem !important;
}

.margin-bottom-md {
    margin-bottom: 2rem !important;
}

.margin-bottom-lg {
    margin-bottom: 5rem !important;
}

.margin-none {
    margin: 0rem !important;
}

.padding-none {
    padding: 0rem !important;
}

.padding-xxsm {
    padding: 0.25rem !important;
}

.padding-xsm {
    padding: 0.5rem !important;
}

.padding-sm {
    padding: 1rem !important;
}

.padding-md {
    padding: 2rem !important;
}

.padding-left-xsm {
    padding-left: .5rem;
}

.padding-left-sm {
    padding-left: 1rem;
}

.padding-left-md {
    padding-left: 2rem;
}

.padding-right-sm {
    padding-right: 1rem;
}

.padding-right-md {
    padding-right: 2rem;
}

.padding-right-xmd {
    padding-right: 3rem;
}

.padding-side-none {
    padding-left: 0;
    padding-right: 0;
}

.padding-side-xxsm {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}

.padding-side-xsm {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.padding-side-sm {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.padding-side-md {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.padding-side-lg {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}

.padding-top-none {
    padding-top: 0 !important;
}

.padding-top-xsm {
    padding-top: 0.5rem !important;
}

.padding-top-sm {
    padding-top: 1rem !important;
}

.padding-bottom-none {
    padding-bottom: 0 !important;
}

.padding-bottom-xsm {
    padding-bottom: 0.5rem !important;
}

.padding-bottom-sm {
    padding-bottom: 1rem !important;
}

.padding-bottom-md {
    padding-bottom: 2rem !important;
}

.padding-vertical-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.padding-vertical-xsm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.padding-vertical-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.no-spinners > input {
    -moz-appearance: textfield !important;
}

.no-spinners > input::-webkit-outer-spin-button,
.no-spinners > input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.trow-primary {
    background-color: white;
}

.trow-secondary {
    background-color: #ddd;
}

.trow-alternating :nth-child(even) {
    background-color: white;
}

.trow-alternating :nth-child(odd) {
    background-color: #ddd;
}

.f-1-5r {
    font-size: 1.5rem !important;
}

.h-1r {
    height: 1rem;
}

.h-2r {
    height: 2rem;
}

.h-3r {
    height: 3rem;
}

.h-4r {
    height: 4rem;
}

.h-5r {
    height: 5rem;
}

.h-6r {
    height: 6rem;
}

.h-7r {
    height: 7rem;
}

.h-10r {
    height: 10rem;
}

.h-30r {
    height: 30rem;
}

.minh-6r {
    min-height: 6rem;
}

.minh-10r {
    min-height: 10rem;
}

.mh-3r {
    max-height: 3rem;
}

.mh-10r {
    max-height: 10rem;
}

.mh-11r {
    max-height: 11rem;
}

.w-25pct {
    width: 25%;
}

.w-50pct {
    width: 50%;
}

.w-75pct {
    width: 75%;
}

.w-80pct {
    width: 80%;
}

.w-1r {
    width: 1rem !important;
}

.w-2r {
    width: 2rem !important;
}

.w-3r {
    width: 3rem !important;
}

.w-4r {
    width: 4rem !important;
}

.w-5r {
    width: 5rem !important;
}

.w-6r {
    width: 6rem !important;
}

.w-7r {
    width: 7rem !important;
}

.w-8r {
    width: 8rem;
}

.w-9r {
    width: 9rem !important;
}

.w-10r {
    width: 10rem !important;
}

.w-11r {
    width: 11rem !important;
}

.w-13r {
    width: 13rem !important;
}

.w-14r {
    width: 14rem !important;
}

.w-15r {
    width: 15rem !important;
}

.w-20r {
    width: 20rem !important;
}

.w-25r {
    width: 25rem !important;
}

.w-30r {
    width: 30rem !important;
}

.w-40r {
    width: 40rem !important;
}

.w-50r {
    width: 50rem !important;
}

.minw-0r {
    min-width: none;
}

.minw-20r {
    min-width: 20rem !important;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

.no-wrap-or-overflow {
    white-space: nowrap;
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.overflow-visible {
    overflow: visible;
}

.overflow-hidden {
    overflow: hidden;
}

.col-spacing-10px {
    border-collapse: separate;
    border-spacing: 10px;
}

table td.shrink {
    white-space: nowrap;
    width: 1%;
}

.table-fix-head {
    overflow-y: auto;
}

.hide-horizontal {
    -ms-overflow-style: none;
    overflow-x: -moz-scrollbars-none;
}

.hide-scrollbar::-webkit-scrollbar {
    width: 0 !important;
    display: none !important;
}

.vertical-scroll {
    overflow-y: scroll;
}

.table-fix-head thead th,
.table-fix-head thead td {
    position: sticky;
    top: 0;
    background: lightgrey;
    z-index: 1000;
}

.table-fix-head table {
    border-collapse: collapse;
}

.unset-height {
    height: unset !important;
}

/* Must specify a height on whatever component you attach this to */
.table-fixed-new {
    overflow: auto;
    height: 30rem;
}

.table-fixed-new thead th,
.table-fixed-new thead td {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: #dbdbdb;
}

.table-fixed-new table {
    border-collapse: collapse;
    width: 100%;
}

.flip-horizontal {
    transform: scaleX(-1);
}

/*#region Table column hiding  */
.hide-column-1 table tr td:nth-child(1),
.hide-column-1 table tr th:nth-child(1) {
    display: none;
}

.hide-column-2 table tr td:nth-child(2),
.hide-column-2 table tr th:nth-child(2) {
    display: none;
}

.hide-column-3 table tr td:nth-child(3),
.hide-column-3 table tr th:nth-child(3) {
    display: none;
}

.hide-column-4 table tr td:nth-child(4),
.hide-column-4 table tr th:nth-child(4) {
    display: none;
}

.hide-column-5 table tr td:nth-child(5),
.hide-column-5 table tr th:nth-child(5) {
    display: none;
}

.hide-column-6 table tr td:nth-child(6),
.hide-column-6 table tr th:nth-child(6) {
    display: none;
}

.hide-column-7 table tr td:nth-child(7),
.hide-column-7 table tr th:nth-child(7) {
    display: none;
}

.hide-column-8 table tr td:nth-child(8),
.hide-column-8 table tr th:nth-child(8) {
    display: none;
}

.hide-column-9 table tr td:nth-child(9),
.hide-column-9 table tr th:nth-child(9) {
    display: none;
}

.hide-column-10 table tr td:nth-child(10),
.hide-column-10 table tr th:nth-child(10) {
    display: none;
}

.hide-column-11 table tr td:nth-child(11),
.hide-column-11 table tr th:nth-child(11) {
    display: none;
}

.hide-column-12 table tr td:nth-child(12),
.hide-column-12 table tr th:nth-child(12) {
    display: none;
}

.hide-column-13 table tr td:nth-child(13),
.hide-column-13 table tr th:nth-child(13) {
    display: none;
}

.hide-column-14 table tr td:nth-child(14),
.hide-column-14 table tr th:nth-child(14) {
    display: none;
}

.hide-column-15 table tr td:nth-child(15),
.hide-column-15 table tr th:nth-child(15) {
    display: none;
}

.terms-and-conditions > p {
    line-height: 100%;
}

/* #endregion */

/* #region installation scheduling */
#installation-scheduling-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
}

#installation-selections-container {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .installation-scheduling-container {
        flex-direction: column;
    }
}
/* #endregion */

/* this was mode into a code-like font by _reboot.scss (for bootstrap) */
pre {
    font-family: var(--bs-font-sans-serif), -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    white-space: pre-wrap;
    margin: 0;
}

.rating-selector-component {
    display: flex;
    justify-content: space-evenly;
}

.rating-selector-item-component {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected-rating-item {
    outline: 2px solid black;
    background-color: rgba(159, 183, 194, 0);
    outline-offset: 3vw;
    animation-name: grow-outline;
    animation-duration: 250ms;
    -webkit-animation-duration: 250ms;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

@keyframes grow-outline {
    0% {
        outline-offset: 3vw;
        background-color: rgba(159, 183, 194, 0);
    }
    100% {
        outline-offset: 0px;
        background-color: rgba(159, 183, 194, 200);
    }
}

@keyframes pulse-star-size {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

.animate-star-ratings > .star-ratings > .star-container:last-child {
    transform: scale(1);
    animation-name: pulse-star-size;
    animation-duration: 750ms;
    -webkit-animation-duration: 750ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}

.installation-tracker-row {
    display: flex;
    margin: 0.5em;
}

.installation-tracker-row div {
    min-height: 2em;
    display: flex;
    align-items: center;
}

.installation-tracker-row div:nth-child(odd) {
    justify-content: end;
    flex: 2;
    text-align: right;
}

.installation-tracker-row div:nth-child(even) {
    justify-content: center;
    flex: 1;
}

.fade-out-synced-text {
    animation: fadeOutSyncedText 3s;
    animation-fill-mode: forwards;
}

@keyframes fadeOutSyncedText {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


.invoice-document-header td {
    border: black solid 1px;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.invoice-document-header .invoice-content-name {
    font-weight: bold;
}

.column-pad-between-children > :not(:first-child)  {
    margin-top: 1rem;
}

.row-pad-between-children > :not(:first-child)  {
    margin-left: 1rem;
}

.flex-even-ratio {
    flex: 1 1 0px;
}

.react-datepicker-popper {
    z-index: 10000 !important;
}