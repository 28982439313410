#pc-page {
    background-color: var(--flat-gray-1);
    width: 100%;
    min-height: 100vw;
}

#pc-page-content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    flex-direction: row;
}

.pc-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: .5em;
    padding-top: 0;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.customer-will-call-button:active {
    background-color: rgb(202, 0, 0) !important;
    color: whitesmoke !important;
}

.customer-will-call-button {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
    cursor: pointer;
    background-color: var(--flat-red);
    color: white;
    font-weight: bold;
    font-size: 12pt;
    border-radius: 4px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
}

.customer-will-call-button-sm {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
    cursor: pointer;
    background-color: var(--flat-red);
    color: white;
    font-weight: bold;
    font-size: 8pt;
    border-radius: 4px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
}

#scheduler-customer-will-call-button {
    background-color: white !important;
    color: rgb(0, 200, 0) !important;
    border: 1px solid rgb(0, 200, 0);
    width: fit-content;
    height: fit-content;
    padding: 0 6px 0 6px;
    border-radius: 25%;
    margin-left: 2rem;
    margin-bottom: 0.5rem;
}

.pc-column-header-base {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    padding: var(--flat-outer-container-padding);
    text-transform: uppercase;
    border-radius: var(--flat-inner-container-radius-sm);
    padding: 4px 0;
    margin-bottom: 24pt;
}

.pc-column-header-base p {
    color: white;
    font-weight: bold;
}

#pc-column-header-unclaimed {
    background-color: var(--flat-gray-4);
}

#pc-column-header-unclaimed p {
    color: black;
}

#pc-column-header-to-schedule {
    background-color: var(--flat-dark-red);
}

#pc-column-header-scheduled {
    background-color: var(--flat-med-blue);
}

#pc-column-header-installing {
    background-color: var(--flat-dark-orange);
}

#pc-column-header-active-order {
    background-color: var(--flat-dark-green);
}

.project-install-region {
    width: 100%;
    flex-grow: 1;
    padding-top: 0.15em;
}

.installation-sub-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    padding: 0 var(--chip-side-padding) 5px var(--chip-side-padding);
}

.installation-sub-card-deemphasize {
    background-color: var(--flat-gray-2);
}

.installation-sub-card-deemphasize .flat-font {
    color: #8e8e8e;
}

.installation-sub-card-separator {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 5;
    width: 100%;
    height: 1px;
    margin-bottom: 5px;;
    background-color: var(--flat-gray-4);
    padding-left: var(--chip-side-padding);
    padding-right: var(--chip-side-padding);
}

.installation-sub-card:nth-of-type(1) .installation-sub-card-separator {
    display: none;
}

.project-coordinator-selector {
    cursor: pointer;
}

.project-coordinator-selector-undefined {
    border-bottom: 1px solid white;
    width: 2rem;
    height: 75%;
    margin-left: 5px;
}

.sub-card-final-row {
    grid-column-start: 1;
    grid-column-end: 5;
    display: grid;
    grid-template-columns: 60% 40%;
}

.installer-name-colspan {
    grid-column: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.installation-date-colspan {
    grid-column: 2;
    white-space: nowrap;
    text-align: right;
}