:root {
    --card-gray:#D1D3D4;
    --card-light-blue: #66BFE8;
    --card-blue: #3689C5;
    --card-red: #EE3439;
    --card-top-spacing: .75em;
    --customer-bar-height: 8px;
}

.pd-column {
    display: flex;
    flex-direction: column;
    flex:1;
    padding:.25em;
}

.base-project-card {
    border-radius: var(--flat-inner-container-radius-sm);
    text-align: center;
}

.project-card {
    --column-card-spacing: .5em;
    margin: var(--column-card-spacing) 0;
    display: flex;
    flex-direction: column;
    --chip-side-padding: 1em;
    font-size: 1em;
}

.customer-bar {
    background-color: var(--flat-gray-4) !important;
    min-height: 2em;
    border-radius: var(--flat-inner-container-radius-sm) var(--flat-inner-container-radius-sm) 0 0;
    padding: var(--flat-outer-container-padding);
    padding-bottom: 0 !important;
    background-color: var(--card-gray);
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: var(--flat-outer-container-padding);
}

.customer-bar-unclaimed {
    border-bottom: var(--customer-bar-height) solid var(--flat-gray-4);
}

.customer-bar-to-schedule {
    border-bottom: var(--customer-bar-height) solid var(--flat-dark-red);
}

.customer-bar-scheduled {
    border-bottom: var(--customer-bar-height) solid var(--flat-med-blue);
}

.customer-bar-installing {
    border-bottom: var(--customer-bar-height) solid var(--flat-dark-orange);
}

.customer-bar-active-order {
    border-bottom: var(--customer-bar-height) solid var(--flat-dark-green);
}

.customer-bar-name {
    grid-row: 1;
    grid-column: 1;
    text-align: left;
    line-height: 1;
    cursor: pointer;
}

.customer-bar-sah-number {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 1;
    font-weight: bold;
}

.customer-bar-bottom-row {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 2;
    display: grid;
    grid-template-columns: 33% 34% 33%;
    column-gap: 3px
}

.customer-bar-city {
    grid-column: 1;
    font-size: .7em;
    text-align: left;
    font-weight: bold;
    font-size: 10pt;
    white-space: nowrap;
}

.customer-bar-status-pill-container {
    grid-column: 2;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: flex-end;
    line-height: 1;
    font-weight: bold;
    font-size: 12pt;
}

.customer-bar-more-icon {
    display: flex;
    align-items: center;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 3;
    width: 10px;
    padding: 0 !important;
    margin-right: 6px !important;
    margin-bottom: 6px !important;
}

.customer-bar-more-icon:hover {
    cursor: pointer;
}

.project-card-bottom-row {
    display: flex;
}

.project-card-bottom-row div {
    background-color: var(--flat-container-color);
    color: var(--theme-main-text-color);
    display: flex;
    column-gap: 4px;
    align-items: center;
    justify-content: center;
}

.project-card-bottom-row div:nth-of-type(1) {
    border-radius: 0 0 0 var(--flat-inner-container-radius-sm);
    padding: 4px 0;
}

.project-card-bottom-row div:nth-of-type(2) {
    border-radius: 0 0 var(--flat-inner-container-radius-sm);
    display: flex;
    justify-content: space-evenly;
    padding: 4px 8px;
}

.project-card-action-icon {
    color: var(--theme-main-text-color);
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
}

.pc-card-sale-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0.5em;
    border-bottom: 3px solid var(--flat-gray-4);
}

.agent-icon {
    max-width: 1.5em !important;
    max-height: 1.5em !important;
    width: 1.5em !important;
    height: 1.5em !important;
}

.gray-text {
    color: var(--card-gray)
}

.option-select {
    margin: 0 .25em;
    font-size: .75em;
    width: 5em;
}

.date-picker {
    max-width: 5em;
    font-size: .75em;
}
