.diff-addition {
    background-color: #B8F597;
}

.diff-deletion {
    background-color: #F5B397;
}

.diff-update {
    background-color: #97D7F5;
}