#job-drawing-container {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
}

#job-drawing-container svg {
    height: 100%;
    width: auto;
}

#job-drawing-container-carpet {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
}

#job-drawing-container-carpet svg {
    height: 100%;
    width: auto;
}