.cell-flash {
    opacity: 1; 
    animation-name: flash;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes flash {
    0% {opacity: 1;}
    1% {opacity: 0;}
    25% {opacity: 0;}
    26% {opacity: 1;}
    100% {opacity: 1;}
}

.diagonal-striping {
    background: repeating-linear-gradient(
        45deg,
        white,
        white 10px,
        lightgray 10px,
        lightgray 20px
    );
}

.diagonal-striping-deletable {
    background: repeating-linear-gradient(
        45deg,
        white,
        white 10px,
        rgb(206, 78, 78) 10px,
        rgb(206, 78, 78) 20px
    );
    border-radius: 5px;
    outline: solid rgb(206, 78, 78) 2px;
    outline-offset: -2px;
}

.make-input-white *.MuiInputBase-input {
    color: var(--flat-white);
}

.make-input-white *.MuiInputLabel-root {
    color: var(--flat-gray-3);
}

.make-input-white *.search-bar-search-icon {
    color: var(--flat-gray-4);
}