/* hide scroll bar but maintain scrollability */
#ledger-main-container .MuiDialogContent-root::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.ledger-table {
    border: 1px solid var(--flat-gray-2);
    font-family: var(--theme-main-text-font-family);
}

.ledger-table th {
    background-color: var(--flat-gray-2);
    text-transform: uppercase;
    font-family: var(--theme-main-text-font-family);
}

.ledger-table tbody tr {
    border: 1px solid var(--flat-gray-2);
}

.ledger-table tbody tr td {
    text-align: center;
    vertical-align: text-top;
}

.ledger-table tbody tr td:not(:last-of-type) {
    border-right: 1px solid var(--flat-gray-2);
    font-size: 12pt;
}

.ledger-table textarea {
    font-size: 12pt;
    font-family: var(--theme-main-text-font-family);
}

#ledger-add-note-button {
    height: 15px;
    width: 15px;
    background-color: black;
    border-radius: 100%;
    margin-left: 8px;
    border: none;
}