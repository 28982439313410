
#search-bar {
    width: 15rem;
    align-self: flex-end;
    margin-bottom: 0.5rem;
    margin-right: .15rem;
}

#search-bar-input::placeholder {
    font-style: italic;
}

#search-results-popper {
    z-index: 9999999999999999;
}

#search-results-container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color: white;
    border: 1px solid black;
    width: 15rem;
    max-height: 30rem;
    overflow-y: auto;
}

#search-results-container > div:not(:nth-of-type(1)) {
    border-top: 1px solid lightgray;
}

#search-results-container > div:hover {
    background-color: lightgray;
}

#search-results-container > div > p {
    cursor: pointer;
}