input.edit-input:hover{
    border-color:lightgray;
    background-color: #f2f2f2;
  }
  input.edit-input{
    border-color:white;  
    border-width: 1px;
  }
  input.edit-input-button{
    position:absolute;
    z-index: 10px; 
    background-color: lightgrey;
  }
  .element-visible { display: block }
  .element-hidden  { display: none }
  